









































































































































































































































































import {FILES_LOCATION} from '@/config';
import {axiosDelete, axiosGet, axiosPatch, axiosPost} from '@/utils/axiosWrapper';
import Vue from 'vue';
import Multiselect from "vue-multiselect";
import VueMarkdown from "@adapttive/vue-markdown";
import {IArticle} from "../../../../api/models/article";
import {ISection} from '../../../../api/models/section';

export default Vue.extend({
  name: "Articles",
  data() {
    return {
      expertMode: false,
      preview: true,

      addArticle: false,
      articles: undefined as undefined | IArticle[],
      newArticle: {} as Record<string, unknown> | IArticle,
      tags: [] as Array<string>,

      selectedArticle: "",
      activeArticle: undefined as undefined | IArticle,
      selectedSection: "",
      activeSection: undefined as undefined | ISection,
      sectionOptions: [] as [] | ISection[],

      files: [],

      status: "",
      addArticleError: "",
      value: [],
      options: [],
      newTag: null,

      currentMaterial: this.$route.params.page.toUpperCase() as "PROJECT" | "REPORT"
    };
  },
  methods: {
    load: function () {
      // Loading Articles
      this.status = "loading data...";
      this.loadArticles()
          .then((a) => {
            this.articles = a;
            if (this.articles) {
              this.tags = [
                ...new Set(
                    this.articles
                        .map((article) => article.tags ? article.tags : [])
                        .reduce((a: Array<string>, b: Array<string>) => a.concat(b), [])
                        .sort()
                ),
              ];
              this.articles = this.articles.map((a) => {
                a.content = a.content.sort((a, b) => a.nr - b.nr);
                return a;
              });
              this.status = "all set";
            } else
              throw Error('Articles is not undefined.')
          })
          .catch((e) => {
            console.warn(e);
            this.status = `error: loading ${this.currentMaterial.toLowerCase()}...`;
          });
      // Loading Files
      this.loadFiles()
          .then((a) => {
            // console.log("Files:", a)
            this.files = a;
          })
          .catch((e) => {
            console.warn(e);
          });
    },
    loadArticles: async function () {
      return (
          await axiosGet("/content/article/material/" + this.currentMaterial)
      ).data;
    },
    loadFiles: async function () {
      return (await axiosGet("/files")).data.map(
          (e: string) => FILES_LOCATION + e
      );
    },
    resetSite: function () {
      this.load();
      this.addArticleError = "";
      this.addArticle = false;
      this.sectionOptions = [];
      this.selectedSection = "";
      this.selectedArticle = "";
      this.activeArticle = undefined;
      this.activeSection = undefined;
    },
    extractSectionOptions: function () {
      if (this.articles) {
        const tempSectionOptionsArticle = this.articles.find(
            (a) => a._id === this.selectedArticle
        )
        if (tempSectionOptionsArticle)
          this.sectionOptions = tempSectionOptionsArticle.content;
      }
    }, resetSection: function () {
      this.activeSection = undefined;
      this.selectedSection = "";
      this.status = `loading ${this.currentMaterial.toLowerCase()}...`;
      this.loadArticles()
          .then((a) => {
            this.articles = a.map((a: IArticle) => {
              a.content = a.content.sort((a, b) => a.nr - b.nr);
              return a;
            });
            this.extractSectionOptions();
            this.status = "all set";
          })
          .catch((e) => {
            console.warn(e);
            this.status = `error: loading ${this.currentMaterial.toLowerCase()}`;
          });
    },
    deleteSection: function () {
      this.status = "deleting section...";
      axiosDelete("/content/section/" + this.selectedSection)
          .then(() => {
            this.status = "all set";
            this.resetSection();
          })
          .catch((e) => {
            console.warn(e.response.data.error);
            this.status = "error: creating section... try again";
          });
    },
    deleteArticle: function () {
      this.status = `deleting ${this.currentMaterial.toLowerCase()}...`;
      axiosDelete("/content/article/" + this.selectedArticle)
          .then(() => {
            this.status = "all set";
            this.resetSite();
          })
          .catch((e) => {
            console.warn(e.response.data.error);
            this.status = `error: deleting ${this.currentMaterial.toLowerCase()}... try again.`;
          });
    },
    saveArticle: async function () {
      this.addArticleError = "";
      this.status = "saving...";

      if (this.newArticle) {
        this.newArticle.material = this.currentMaterial
        if (this.newArticle.mainPoints) {
          this.newArticle.mainPoints = String(this.newArticle.mainPoints).split("\n");
        }

        axiosPost(
            "/content/article/new",
            this.newArticle
        )
            .then(() => {
              this.resetSite();
            })
            .catch((e) => {
              console.warn(e.response.data.error);
              this.addArticleError = "Error saving the article... Try later again.";
            })
            .finally(() => (this.status = "all set"));
      }
    },
    publishArticle: function () {
      this.status = "publishing...";
      if (this.activeArticle) {
        axiosPatch("/content/publish", {
          published: this.activeArticle.published,
          id: this.selectedArticle,
        })
            .then(() => (this.status = "all set"))
            .catch((e) => {
              console.warn(e);
              this.status = "error: publishing, reload site and try again";
            });
      }
    },
    addSection: function () {
      this.status = "creating new section";
      const tempSelectedArticle = this.articles?.find((a) => a._id === this.selectedArticle)
      if (tempSelectedArticle) {
        const section = {
          nr:
              tempSelectedArticle.content
                  .length + 1,
        };
        axiosPost("/content/section/new", {
          article: this.selectedArticle,
          section: section,
        })
            .then(() => {
              this.resetSection();
              this.status = "all set";
            })
            .catch((e) => {
              console.warn(e.response.data.error);
              this.status = "error: creating section... try again";
            });
      }
    },
    saveChanges: function (kind: number, part: string, content: number | string) {
      this.status = "saving...";
      let api = "";
      switch (kind) {
        case 0:
          api = `/content/article/${this.selectedArticle}/${part}`;
          break;
        case 1:
          api = `/content/section/${this.selectedSection}/${part}`;
          break;
      }
      axiosPatch(api, {toChange: content})
          .then(() => {
            this.status = "all set";
          })
          .catch((e) => {
            console.warn(e);
            this.status = "error: saving changes - reload site";
          });
    },
    articleSelected: function () {
      this.selectedSection = "";
      this.activeSection = undefined;
      this.extractSectionOptions()
      this.activeArticle = this.articles?.find(
          (a) => a._id === this.selectedArticle
      );
    },
    checkCreateSection: function () {
      this.activeSection = this.articles
          ?.find((a) => a._id === this.selectedArticle)
          ?.content.find((s) => s._id === this.selectedSection);
    },
  },
  components: {
    VueMarkdown,
    Multiselect,
  },
  beforeMount: function () {
    this.load();
  },
  watch: {
    $route(to, from) { // react to route changes...
      if (to !== from) {
        location.reload();
      }
    }
  }
});
